.App {
    padding: 1em;
    box-sizing: border-box;
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

canvas {
    margin-top: 1em;
    flex: 2;
}

.App-data {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex: 1;
    min-height: 30vh;
}

label {
    flex: 1;
    margin: 0 10px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

textarea {
    flex: 1;
}

a, a:visited {
    color: #61dafb;
}

.App-error {
    color: red;
}